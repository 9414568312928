import { gql } from "@apollo/client";

export const PASS_EMAIL_INFO = gql`
  mutation passEmailInfo(
    $emailAddress: EmailAddress!
    $briteVerifyResp: ms_BriteVerify_Input!
  ) {
    ms_passEmailInfo(
      input: { emailAddress: $emailAddress, briteVerifyResp: $briteVerifyResp }
    ) {
      message
    }
  }
`;
export const PASS_PRODUCT_INFO = gql`
  mutation passProductInfo($cartId: String!) {
    ms_passProdInfo(cartId: $cartId) {
      message
    }
  }
`;

export const PASS_CONTACT_INFO = gql`
  mutation passContactInfo(
    $firstName: String!
    $lastName: String!
    $shippingAddress: ms_SmartyAddress_Input!
    $billingAddress: ms_SmartyAddress_Input!
  ) {
    ms_passContactInfo(
      input: {
        firstName: $firstName
        lastName: $lastName
        shippingAddress: $shippingAddress
        billingAddress: $billingAddress
      }
    ) {
      message
    }
  }
`;

export const PASS_USER_INFO = gql`
  mutation passUserInfo($ubf: String!, $clientIp: String!) {
    ms_passUserInfo(input: { ubf: $ubf, clientIp: $clientIp }) {
      message
    }
  }
`;

export const QUALIFICATION_RESPONSE = gql`
  query qualificationResponse($contentIdentifier: String!) {
    aem_qualificationResponseModalList(
      filter: {
        contentIdentifier: { _expressions: { value: $contentIdentifier } }
      }
    ) {
      items {
        contentIdentifier
        title
        body
        listItems {
          json
        }
        primarybutton {
          buttonText
          buttonLink
          buttonType
          includeArrow
        }
        secondaryButton {
          buttonText
          buttonType
          includeArrow
        }
        subtext
      }
    }
  }
`;
